.contents_notfound .cmn_contents_header .title {
  text-align: center;
}

.contents_notfound .cmn_contents_header .title .ja,
.contents_notfound .cmn_contents_header .title .en {
  display: block;
  font-weight: bold;
}

.contents_notfound .cmn_contents_header .title .ja {
  font-size: 30px;
}

@media screen and (max-width: 767px) {
  .contents_notfound .cmn_contents_header .title .ja {
    font-size: 24px;
    line-height: 1.25;
  }
}

.contents_notfound .cmn_contents_header .title .en {
  color: #04a311;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .contents_notfound .cmn_contents_header .title .en {
    margin-top: 5px;
    font-size: 11px;
  }
}

.contents_notfound .button {
  margin-top: 50px;
}

.contents_notfound .button .cmn_button {
  margin: 0 auto;
  max-width: 450px;
}
