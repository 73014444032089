@import 'includes/include';

.contents_notfound {

	.cmn_contents_header {
		.title {
			text-align: center;

			.ja,
			.en {
				display: block;
				font-weight: bold;
			}
			.ja {
				font-size: 30px;

				@include SP {
					font-size: 24px;
					line-height: 1.25;
				}
			}
			.en {
				color: $color_deepgreen;
				font-size: 14px;

				@include SP {
					margin-top: 5px;
					font-size: 11px;
				}
			}
		}
	}
	.button {
		margin-top: 50px;

		.cmn_button {
			margin: 0 auto;
			max-width: 450px;
		}
	}
}
